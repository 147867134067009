import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

const Testimonials = () => {
  const settings = {
    arrows: false,
    dots: true,
    swipe: true,
    fade: true,
    speed: 1000,
    slidesPerRow: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    loop: true,
  }

  const clientImages = useStaticQuery(graphql`
    query ClientPhotos {
      client_1: file(relativePath: { eq: "faisal.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client_2: file(relativePath: { eq: "dan.webp" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client_3: file(relativePath: { eq: "divya.webp" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client_4: file(relativePath: { eq: "vineet.webp" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
    }
  `)

  return (
    <div className="testimonials-section">
      <div className="container-lg">
        <div className="extra-gutter left right">
          {/* <div className="section-tag black">
            <span>Testimonials</span>
          </div> */}
          <h2
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
            className="a-test-h1 text text-h2 text-title-font text-black2 text-fw-regular gap-3x"
          >
            We had them at hello.
          </h2>
        </div>
        <div className="a-test-row row slider-parent extra-gutter left right">
          <div
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
            data-sal-duration="800"
            className="col-md-2"
          >
            <span className="qoutes">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="195.48"
                height="146.61"
                viewBox="0 0 195.48 146.61"
              >
                <defs></defs>
                <g transform="translate(0 -4)">
                  <g transform="translate(0 4)">
                    <path
                      fill="#030104;"
                      d="M0,4V150.61L73.3,77.3V4Z"
                      transform="translate(0 -4)"
                    />
                    <path
                      fill="#030104;"
                      d="M20,4V150.61L93.3,77.3V4Z"
                      transform="translate(102.175 -4)"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="500"
            data-sal-easing="ease"
            data-sal-duration="800"
            className="col-md-10"
          >
            <div className="slider-wrapper">
              <Slider {...settings}>
                <div>
                  <div className="testimonial item">
                    <p className="text text-p-md text-black3 text-fw-regular gap-2x">
                      Excellent work guys. It was a crazy project that most
                      agencies I know would run away from, and a lot of them
                      did. Going headless is always risky if you don't have the
                      know-how and the right partner. Glad to say it all worked
                      out with lots of proud faces.
                    </p>
                    <div className="author">
                      <div className="avatar">
                        <Img
                          fluid={clientImages.client_1.childImageSharp.fluid}
                          alt="client"
                        />
                      </div>
                      <div className="meta">
                        <span className="name text text-p-lg text-black3 text-fw-bold">
                          Faisal Mumtaz
                        </span>
                        <span className="designation text text-p-md text-black3">
                          Redsea
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="testimonial item">
                    <p className="text text-p-md text-black3 text-fw-regular gap-2x">
                      We have been working with Code Rapper on four different
                      shopify websites and he has been excellent. He has been
                      very efficient at quoting, replying to queries and turning
                      around the custom coding and app implementations required.
                      He has also been very good at suggesting solutions, when
                      we were not clear on what was possible and his pricing has
                      been competitive. I would highly recommend him.
                    </p>
                    <div className="author">
                      <div className="avatar">
                        <Img
                          fluid={clientImages.client_2.childImageSharp.fluid}
                          alt="client"
                        />
                      </div>
                      <div className="meta">
                        <span className="name text text-p-lg text-black3 text-fw-bold">
                          Dan Black
                        </span>
                        <span className="designation text text-p-md text-black3">
                          Director at black+blum
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="testimonial item">
                    <p className="text text-p-md text-black3 text-fw-regular gap-2x">
                      Gopi and his team are brilliant and stepped to challenge
                      to deliver 400-things.com in super tight timeframe. It was
                      an absolute pleasure working with Gopi, he is truly a
                      Shopify expert. We approached Gopi to build 400-things.com
                      as a last resort, after a near death experience with two
                      other Shopify 'experts'. Gopi and his team went out of
                      their way to make things happen in 2 weeks. Gopi and his
                      team understood the importance of design and did complete
                      justice to it. We are very encouraged by their attention
                      to detail and commitment to our success. We are proud of
                      the outcome achieved.
                    </p>
                    <div className="author">
                      <div className="avatar">
                        <Img
                          fluid={clientImages.client_3.childImageSharp.fluid}
                          alt="client"
                        />
                      </div>
                      <div className="meta">
                        <span className="name text text-p-lg text-black3 text-fw-bold">
                          Divya Hegde
                        </span>
                        <span className="designation text text-p-md text-black3">
                          Founder, 400 Things
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="testimonial item">
                    <p className="text text-p-md text-black3 text-fw-regular gap-2x">
                      Superb experience, start to finish ... rare mix of skill
                      and responsiveness. Committed to the outcome, not just the
                      deliverable. Outstanding with remote collaboration which
                      made the entire process easy and stress-free. I would
                      absolutely recommend Gopi and the team at Code Rapper.
                    </p>
                    <div className="author">
                      <div className="avatar">
                        <Img
                          fluid={clientImages.client_4.childImageSharp.fluid}
                          alt="client"
                        />
                      </div>
                      <div className="meta">
                        <span className="name text text-p-lg text-black3 text-fw-bold">
                          Vineet Chikarmane
                        </span>
                        <span className="designation text text-p-md text-black3">
                          Co-Founder, Encrustd
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
