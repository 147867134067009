import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Layout from "../components/layout"
import SEO from "../components/seo"
import caseStudyClientLogo from "../images/redsea_logo.svg"
import Testimonials from "../components/Testimonials"
import CrButton from "../components/arrowButton"
import CrPlayer from "../components/CrPlayer"
import ogImage from "../images/Frame-7.png"
import { Helmet } from "react-helmet"
import ClutchWidget from "../components/clutchWidget"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import Accelerators from "../components/Accelerators"

const IndexPage = () => {
  const imageData = useStaticQuery(graphql`
    query Images {
      about_image: file(relativePath: { eq: "Homepage_About.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work_image: file(relativePath: { eq: "work-image-redsea.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service_1: file(relativePath: { eq: "Homepage_design.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service_2: file(relativePath: { eq: "Homepage_dev.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service_3: file(relativePath: { eq: "optimization-img.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      client_1: file(relativePath: { eq: "clients/Client_Logo1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_2: file(relativePath: { eq: "clients/Client_Logo2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_3: file(relativePath: { eq: "clients/Client_Logo3.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_4: file(relativePath: { eq: "clients/Client_Logo4.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_5: file(relativePath: { eq: "clients/Client_Logo5.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_6: file(relativePath: { eq: "clients/Client_Logo6.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_7: file(relativePath: { eq: "clients/Client_Logo7.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_8: file(relativePath: { eq: "clients/Client_Logo8.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_9: file(relativePath: { eq: "clients/Client_Logo9.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_10: file(relativePath: { eq: "clients/Client_Logo10.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_11: file(relativePath: { eq: "clients/Client_Logo11.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_12: file(relativePath: { eq: "clients/Client_Logo12.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_13: file(relativePath: { eq: "clients/Client_Logo13.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_14: file(relativePath: { eq: "clients/Client_Logo14.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_15: file(relativePath: { eq: "clients/Client_Logo15.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      client_16: file(relativePath: { eq: "clients/Client_Logo16.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_1: file(relativePath: { eq: "case-study/Educated_beards_1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_1a: file(relativePath: { eq: "case-study/Educated_beards_2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_2: file(relativePath: { eq: "case-study/Freeletics_1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_2a: file(relativePath: { eq: "case-study/Freeletics_2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_3: file(relativePath: { eq: "case-study/Pura_1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_3a: file(relativePath: { eq: "case-study/Pura_2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_4: file(relativePath: { eq: "case-study/Redsea_1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_4a: file(relativePath: { eq: "case-study/Redsea_2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_5: file(relativePath: { eq: "case-study/Regal_1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_5a: file(relativePath: { eq: "case-study/Regal_2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_6: file(relativePath: { eq: "case-study/Urban_Ladder_1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      work_6a: file(relativePath: { eq: "case-study/Urban_Ladder_2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `)
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    variableWidth: true,
    pauseOnHover: false,
  }

  return (
    <Layout>
      <SEO
        title="Coderapper | eCommerce Digital Agency | Shopify Experts"
        description="Scale your brand with leading Shopify experts and Magento consultants. Build eCommerce stores and products that wow customers, drive revenue and deliver results."
        url="https://coderapper.com"
        image={`https://www.coderapper.com${ogImage}`}
      />
      <div className="banner">
        <div className="container-lg">
          <div className="extra-gutter left">
            <h1
              className=" text text-h1 text-title-font text-fw-regular text-black2 gap-2x"
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-easing="ease"
              data-sal-duration="150"
            >
              Reimagine commerce beyond products & platforms.
            </h1>
            <p
              className=" text text-p-lg text-fw-light text-black1 "
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="150"
            >
              Build eCommerce experiences, apps, and storefronts that captivate
              customers and nurture communities.
            </p>
            <div
              className=" text text-p-lg text-fw-light text-black1 "
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
              data-sal-duration="150"
            >
              <CrButton path="/projects" type="light" label="View Our Work" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="video-section container-lg">
        <div>
          <CrPlayer src={"../../videos/redsea-promo.mp4"} muted={true} />
        </div>
      </div> */}
      <section className="client-logo">
        <div className="client-logo-anim">
          <Slider {...settings}>
            <div className="logo">
              <GatsbyImage
                image={imageData.client_1.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_2.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_3.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_4.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_5.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_6.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_7.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_8.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_9.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_10.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_11.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_12.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_13.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_14.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_15.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>

            <div className="logo">
              <GatsbyImage
                image={imageData.client_16.childImageSharp.gatsbyImageData}
                alt="client"
              />
            </div>
          </Slider>
        </div>
      </section>
      <Accelerators />

      <div className="about-section">
        <div className="container-lg">
          <div className="row">
            <div className="col-md-8 ">
              <div className="extra-gutter left">
                <div>
                  {/* <div className="a-about section-tag black">
                    <span>About</span>
                  </div> */}
                  <h2
                    data-sal="slide-up"
                    data-sal-delay="100"
                    data-sal-easing="ease"
                    data-sal-duration="150"
                    className="a-h2 text text-h2 text-title-font text-black2 text-fw-regular gap-2x"
                  >
                    Full-stack specialists for future-facing brands.
                  </h2>
                  <p
                    data-sal="slide-up"
                    data-sal-delay="200"
                    data-sal-easing="ease"
                    data-sal-duration="150"
                    className="a-about-cnt1 text text-p-lg text-black3 text-fw-regular gap-1x"
                  >
                    We turn moonshots to milestones with data-driven design,
                    headless frameworks, and timeless GTM strategies.
                  </p>
                  <div
                    data-sal="slide-up"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    data-sal-duration="150"
                    className="a-about-btn gap-1x"
                  >
                    {/* <CrButton path="/" type="light" label="Meet Our Team" /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 ">
              <div
                className="about-img extra-gutter left gap-1x"
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <Img
                  fluid={imageData.about_image.childImageSharp.fluid}
                  alt="about-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about-section">
        <div className="container-lg">
          <div className="row">
            <div className="col-md-6 ">
              <div>
                <h2
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-easing="ease"
                  data-sal-duration="150"
                  className="a-h2 text text-h2 text-title-font text-black2 text-fw-regular gap-2x"
                >
                  Talk is cheap, so we brought receipts.
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex justify-content-md-end">
                <div
                  data-sal="slide-up"
                  data-sal-delay="400"
                  data-sal-easing="ease"
                  data-sal-duration="150"
                >
                  <CrButton
                    path="/projects"
                    type="light"
                    label="ALL CASE STUDY"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="work-card">
                <a href="/projects/redsea">
                  <div className="work-card__img">
                    <StaticImage
                      src="../images/case-study/Redsea_1.png"
                      alt="Redsea"
                      placeholder="blurred"
                      layout="CONSTRAINED"
                    />
                    <div className="work-card__img-hover">
                      <StaticImage
                        src="../images/case-study/Redsea_2.png"
                        alt="Redsea"
                        placeholder="blurred"
                      />
                    </div>
                  </div>
                  <div className="work-card__description">
                    <h3>Redsea</h3>
                    <p>Shopify Plus | Next.js | Headless Commerce</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="work-card">
                <a href="/projects/staedium">
                  <div className="work-card__img">
                    <StaticImage
                      src="../images/case-study/Freeletics_1.png"
                      alt="Freeletics"
                      placeholder="blurred"
                      layout="CONSTRAINED"
                    />
                    <div className="work-card__img-hover">
                      <StaticImage
                        src="../images/case-study/Freeletics_2.png"
                        alt="Freeletics"
                        placeholder="blurred"
                      />
                    </div>
                  </div>
                  <div className="work-card__description">
                    <h3>Freeletics</h3>
                    <p>Shopify Plus | Brand Launch | Teaser Website</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="work-card">
                <a href="/projects/pura">
                  <div className="work-card__img">
                    <StaticImage
                      src="../images/case-study/Pura_1.png"
                      alt="Pura"
                      placeholder="blurred"
                      layout="CONSTRAINED"
                    />
                    <div className="work-card__img-hover">
                      <StaticImage
                        src="../images/case-study/Pura_2.png"
                        alt="Pura"
                        placeholder="blurred"
                      />
                    </div>
                  </div>
                  <div className="work-card__description">
                    <h3>Pura</h3>
                    <p>Shopify Plus | UI/UX Design | Advanced Search</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="work-card">
                <a href="/projects/regal">
                  <div className="work-card__img">
                    <StaticImage
                      src="../images/case-study/Regal_1.png"
                      alt="Regal"
                      placeholder="blurred"
                      layout="CONSTRAINED"
                    />
                    <div className="work-card__img-hover">
                      <StaticImage
                        src="../images/case-study/Regal_2.png"
                        alt="Regal"
                        placeholder="blurred"
                      />
                    </div>
                  </div>
                  <div className="work-card__description">
                    <h3>Regal Cinemas</h3>
                    <p>Magento 2 | Adobe Commerce | Zend Framework</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="work-card">
                <a href="/projects/urban-ladder">
                  <div className="work-card__img">
                    <StaticImage
                      src="../images/case-study/Urban_Ladder_1.png"
                      alt="Urban_Ladder"
                      placeholder="blurred"
                      layout="CONSTRAINED"
                    />
                    <div className="work-card__img-hover">
                      <StaticImage
                        src="../images/case-study/Urban_Ladder_2.png"
                        alt="Urban_Ladder"
                        placeholder="blurred"
                      />
                    </div>
                  </div>
                  <div className="work-card__description">
                    <h3>Urban Ladder</h3>
                    <p>JioMart | Spree | Ruby on Rails</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="work-card">
                <a href="/projects/educated-beards">
                  <div className="work-card__img">
                    <StaticImage
                      src="../images/case-study/Educated_beards_1.png"
                      alt="Educated_beards"
                      placeholder="blurred"
                      layout="CONSTRAINED"
                    />
                    <div className="work-card__img-hover">
                      <StaticImage
                        src="../images/case-study/Educated_beards_2.png"
                        alt="Educated_beards"
                        placeholder="blurred"
                      />
                    </div>
                  </div>
                  <div className="work-card__description">
                    <h3>Educated Beards</h3>
                    <p>NodeJS | React | Shopify | GraphQL | MongoDB | Heroku</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <WorkSlider></WorkSlider> */}
      {/* <div className="works-section">
        <div className="container-lg">
          <div className="row ">
            <div className="col-md-5">
              <div className="works-vertical-fit">
                <div className="extra-gutter left content">

                  <div
                    className="client-logo gap-2x"
                    data-sal="slide-up"
                    data-sal-delay="100"
                    data-sal-easing="ease"
                    data-sal-duration="150"
                  >
                    <img src={caseStudyClientLogo} alt="logo" />
                  </div>
                  <div
                    className="tagline"
                    data-sal="slide-up"
                    data-sal-delay="200"
                    data-sal-easing="ease"
                    data-sal-duration="150"
                  >
                    <h3 className="text text-h3 text-white1 text-title-font text-fw-light gap-remove">
                      Building An Immersive eCommerce Experience.
                    </h3>
                  </div>
                  <div
                    data-sal="slide-up"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    data-sal-duration="150"
                  >
                    <CrButton
                      path="/projects/redsea"
                      type="dark"
                      label="VIEW CASE STUDY"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="works-vertical-fit">
                <div
                  className="featured-image"
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-easing="ease"
                  data-sal-duration="150"
                >
                  <Img
                    fluid={imageData.work_image.childImageSharp.fluid}
                    alt="redsea"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="solutions-section">
        <div className="container-lg">
          <div className="row extra-gutter left right">
            <div className="col-md-10">
              {/* <div className="section-tag black">
                <span>What We Offer</span>
              </div> */}
              <h2
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="150"
                className="a-h4 text text-h2 text-title-font text-fw-regular text-black2 gap-1x"
              >
                Let’s craft your customer experience.
              </h2>
              <p
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="150"
                className="a-sol-cnt2 text text-p-md text-fw-regular text-black1 gap-2x"
              >
                Explore our holistic e-commerce services.
              </p>
            </div>
          </div>
          <div className="solutions-flex-row extra-gutter left right">
            <div className="item a-sol-item">
              <div
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <Img
                  fluid={imageData.service_1.childImageSharp.fluid}
                  alt="design"
                />
              </div>

              <h4
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-easing="ease"
                data-sal-duration="150"
                className="a-sol-text text text-h5 text-fw-strong text-black2 gap-1x"
              >
                Design
              </h4>
              <p
                data-sal="slide-up"
                data-sal-delay="800"
                data-sal-easing="ease"
                data-sal-duration="150"
                className="text text-p-md text-fw-regular text-black1 gap-2x"
              >
                Explore opportunities, create roadmaps and transform your
                e-commerce ideas into scalable and human-centric design. Craft
                immersive CX for frictionless shopping.
              </p>
            </div>
            <div className="item a-sol-item">
              <div
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <Img
                  fluid={imageData.service_2.childImageSharp.fluid}
                  alt="development"
                />
              </div>

              <h4
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-easing="ease"
                data-sal-duration="150"
                className="text text-h5 text-fw-strong text-black2 gap-1x"
              >
                Development
              </h4>
              <p
                data-sal="slide-up"
                data-sal-delay="800"
                data-sal-easing="ease"
                data-sal-duration="150"
                className="text text-p-md text-fw-regular text-black1 gap-2x"
              >
                Decode business challenges with clean and efficient store
                development, and build custom applications that simplify
                operations and boost your bottomline.
              </p>
            </div>
            <div className="item a-sol-item">
              <div
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <Img
                  fluid={imageData.service_3.childImageSharp.fluid}
                  alt="development"
                />
              </div>

              <h4
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-easing="ease"
                data-sal-duration="150"
                className="text text-h5 text-fw-strong text-black2 gap-1x"
              >
                Optimization
              </h4>
              <p
                data-sal="slide-up"
                data-sal-delay="800"
                data-sal-easing="ease"
                data-sal-duration="150"
                className="text text-p-md text-fw-regular text-black1 gap-2x"
              >
                Build blueprints for effective conversion through rigorous
                analytics and data-driven optimization. Automate your marketing
                with organic and omni-channel strategies.
              </p>
            </div>
          </div>
          <div className="extra-gutter left right">
            <div
              data-sal="slide-up"
              data-sal-delay="400"
              data-sal-easing="ease"
              data-sal-duration="150"
              className="a-sol-btn"
            >
              <CrButton
                path="/services"
                type="light"
                label="What We Do"
                className="gap-remove gap-top-remove"
              />
            </div>
          </div>
        </div>
      </div>
      <Testimonials></Testimonials>
      <div className="client-section">
        <div className="container-lg">
          <div className="row extra-gutter left right">
            <div className="col-md-8">
              {/* <div className="section-tag black">
                <span>Our Clients</span>
              </div> */}
              <h2
                data-sal="slide-up"
                data-sal-delay="100"
                data-sal-easing="ease"
                data-sal-duration="150"
                className="a-h5 text text-h2 text-title-font text-fw-regular text-black2 gap-4x"
              >
                We’ve built lasting relationships with brands from across the
                world.
              </h2>
            </div>
          </div>
          <div className="a-client-row row clients extra-gutter left right align-items-center">
            <div className="col-6 col-sm-6 col-md-3">
              <div
                className="logo"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <GatsbyImage
                  image={imageData.client_1.childImageSharp.gatsbyImageData}
                  alt="client"
                />
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div
                className="logo"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <GatsbyImage
                  image={imageData.client_2.childImageSharp.gatsbyImageData}
                  alt="client"
                />
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div
                className="logo"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <GatsbyImage
                  image={imageData.client_3.childImageSharp.gatsbyImageData}
                  alt="client"
                />
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div
                className="logo"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <GatsbyImage
                  image={imageData.client_4.childImageSharp.gatsbyImageData}
                  alt="client"
                />
              </div>
            </div>
            <div className="a-client-col5 col-6 col-sm-6 col-md-3">
              <div
                className="logo"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <GatsbyImage
                  image={imageData.client_5.childImageSharp.gatsbyImageData}
                  alt="client"
                />
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div
                className="logo"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <GatsbyImage
                  image={imageData.client_6.childImageSharp.gatsbyImageData}
                  alt="client"
                />
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div
                className="logo"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <GatsbyImage
                  image={imageData.client_7.childImageSharp.gatsbyImageData}
                  alt="client"
                />
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <div
                className="logo"
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                data-sal-duration="150"
              >
                <GatsbyImage
                  image={imageData.client_8.childImageSharp.gatsbyImageData}
                  alt="client"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
