import React from "react"
import CrButton from "./arrowButton"
import { StaticImage } from "gatsby-plugin-image"
import SyaImage from "../images/sya.png"
import gsap from "gsap"
import { useRef, useEffect } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import CrPlayer from "./CrPlayer"
import ReactPlayer from "react-player/lazy"
import ScrollToPlugin from "gsap/ScrollToPlugin"
gsap.registerPlugin(ScrollToPlugin)

const Accelerators = () => {
  const acceleratorsRef = useRef(null)
  const TextOneRef = useRef(null)
  const TextTwoRef = useRef(null)


  const btnHandler = (elementId) => {
    gsap.from('#text-1', {
      duration: 1, // Duration of the scroll animation in seconds
      scrollTo: {
        y: 200,
      },
    });
  };

  useEffect(() => {
    // Check if the acceleratorsRef exists
    if (acceleratorsRef.current && window.innerWidth > 768) {
      gsap.registerPlugin(ScrollTrigger)

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: acceleratorsRef.current,
          toggleActions: "restart complete reverse resume",
          start: "top top",
          scrub: true,
          pin: true,
        },
      })

      tl.to("#text-1", {
        autoAlpha: 0,
      })
      tl.from("#text-2", {
        autoAlpha: 0,
        y: 40,
      })
      tl.set(
        "#img-1",
        {
          autoAlpha: 0,
        },
        "<"
      )
      tl.from(
        "#img-2",
        {
          autoAlpha: 0,
        },
        "<"
      )
      tl.to(
        ".line__bar",
        {
          y: 40,
        },
        "<"
      )
    }


  }, [])

  return (
    <div className="accelerators " ref={acceleratorsRef}>
      <div className="container-lg">
        <div className="extra-gutter left">
          <div className="accelerators__grid">
            <div className="accelerators__grid-left">
              <div
                className="accelerators__grid-left__text "
                ref={TextOneRef}
                id="text-1"
              >
                <h2 className="text-h2 text-black2 text-title-font ">Sya</h2>
                <div className="text-p-lg text-black2 text-fw-regular">
                  Sya is a next-gen accelerator for jewellery eCommerce, powered
                  by AR and AI.
                </div>
                <CrButton
                  target="_blank"
                  btnType="link"
                  path="https://sya.coderapper.com/"
                  type="light"
                  label="Explore Now"
                  rel="noopener noreferrer"
                  className="gap-remove gap-top-remove"
                />
              </div>
              <div
                className="accelerators__grid-left__text"
                ref={TextTwoRef}
                id="text-2"
              >
                <h2 className="text-h2 text-black2 text-title-font ">Marsel</h2>
                <div className="text-p-lg text-black2 text-fw-regular">
                  AI-powered Shopify app that simplifies discovery, improves
                  AOV, and maximizes revenue.
                </div>
                <CrButton
                  target="_blank"
                  btnType="link"
                  path="https://www.coderapper.com/marsel"
                  type="light"
                  rel="noopener noreferrer"
                  label="Explore Now"
                  className="gap-remove gap-top-remove"
                />
              </div>
            </div>
            <div className="accelerators__grid-right">
              <div className="accelerators__grid-right__image" id="img-1">
                {/* <StaticImage src="../images/sya.png" alt="img" /> */}
                {/* <CrPlayer src={"../../videos/sya.mp4"} muted={true} /> */}
                <ReactPlayer
                  url={"../../videos/sya.mp4"}
                  width="100%"
                  height="100%"
                  playing={true}
                  muted
                  loop={true}
                ></ReactPlayer>
              </div>
              <div className="accelerators__grid-right__image" id="img-2">
                {/* <StaticImage src="../images/sya.png" alt="img" /> */}
                {/* <CrPlayer src={"../../videos/marsel.mp4"} muted={true} /> */}
                <ReactPlayer
                  url={"../../videos/marsel.mp4"}
                  width="100%"
                  height="100%"
                  playing={true}
                  muted
                  loop={true}
                ></ReactPlayer>
              </div>
            </div>
            <div className="indicators">
              <button className="indicators__btn" onClick={(e) => btnHandler(e)}>01</button>
              <div className="line"><span className="line__bar"></span></div>
              <button className="indicators__btn" onClick={(e) => btnHandler(e)}>02</button>
            </div>
          </div>
          <div className="mob">
            <div className="mob__grid">
              <div className="mob__grid-content">
                <div className="mob__grid-content__text">
                  <h2 className="text-h2 text-black2 text-title-font ">Sya</h2>
                  <div className="text-p-lg text-black2 text-fw-regular">
                    Sya is a next-gen accelerator for jewellery eCommerce,
                    powered by AR and AI.
                  </div>
                  <CrButton
                    target="_blank"
                    btnType="link"
                    path="https://sya.coderapper.com/"
                    type="light"
                    label="Explore Now"
                    rel="noopener noreferrer"
                    className="gap-remove gap-top-remove"
                  />
                </div>
                <div>
                  <ReactPlayer
                    url={"../../videos/sya.mp4"}
                    width="100%"
                    height="100%"
                    playing={true}
                    muted
                    loop={true}
                  ></ReactPlayer>
                </div>
              </div>
              <div className="mob__grid-content">
                <div className="mob__grid-content__text">
                  <h2 className="text-h2 text-black2 text-title-font ">
                    Marsel
                  </h2>
                  <div className="text-p-lg text-black2 text-fw-regular">
                    AI-powered Shopify app that simplifies discovery, improves
                    AOV, and maximizes revenue.
                  </div>
                  <CrButton
                    target="_blank"
                    btnType="link"
                    path="https://www.coderapper.com/marsel"
                    type="light"
                    rel="noopener noreferrer"
                    label="Explore Now"
                    className="gap-remove gap-top-remove"
                  />
                </div>
                <div>
                  <ReactPlayer
                    url={"../../videos/marsel.mp4"}
                    width="100%"
                    height="100%"
                    playing={true}
                    muted
                    loop={true}
                  ></ReactPlayer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accelerators
